/* eslint-disable import/no-named-as-default-member */
import axios from 'axios';
import qs from 'qs';
import mock from '@/item';

let usingMock = false;
const instance = axios.create({
  baseURL: '/api',
});
let accessToken = localStorage.getItem('token') || '';

function getHeader(config) {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  if (config?.isForm) {
    headers['Content-Type'] = 'multipart/form-data';
  }
  return headers;
}

export function setMock(value) {
  usingMock = value;
}

export async function login(username, password) {
  if (usingMock) {
    return mock.login(username, password);
  }
  const rsp = await instance.post('/login', qs.stringify({ username, password }));
  accessToken = rsp.data.access_token;
  return accessToken;
}

export async function uploadItem(file) {
  if (usingMock) {
    return mock.uploadItem(file);
  }
  const data = new FormData();
  data.append('file', file);
  await instance.post('/upload', data, { headers: getHeader({ isForm: true }) });
  return true;
}

export async function getList() {
  if (usingMock) {
    return mock.getList();
  }
  const rsp = await instance.get('/datas', { headers: getHeader() });
  return rsp.data;
}

export async function getItemDetail(id) {
  if (usingMock) {
    return mock.getItemStatus(id);
  }
  const rsp = await instance.get(`/data/${id}`, { headers: getHeader() });
  return rsp.data;
}

export async function getItemFailList(id) {
  if (usingMock) {
    return mock.getItemFailList(id);
  }
  const rsp = await instance.get(`/data/${id}/fail`, { headers: getHeader() });
  return rsp.data;
}
