<template lang="pug">
#login.flex.column.align-center.justify-center
  .login-form.flex.column.p-20.gap-20
    .title 登入
    .col.flex.column.align-stretch.gap-4
      .name.text-align-left 帳號
      el-input(v-model="account")
    .col.flex.column.align-stretch.gap-4
      .name.text-align-left 密碼
      el-input(v-model="password", type="password")
    el-button.mt-20(type="primary", round, @click="login") 登入
</template>

<script>
import { mapMutations } from 'vuex';
import { login } from '@/api';

export default {
  data() {
    return {
      account: '',
      password: '',
    };
  },
  methods: {
    ...mapMutations(['setToken']),
    login() {
      this.$execWithLoading(async () => {
        const token = await login(this.account, this.password);
        console.log(token);
        this.setToken(token);
        this.$router.push('/');
      }, () => {
        this.$showError('登入失敗');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  background: rgb(10, 66, 106);
  .login-form {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    width: 350px;
    .title {
      font-size: 32px;
    }
  }
}
</style>
